import { useQuery } from "react-query";
import { getExperts } from "../Services/Https/expertForm";
import { ExpertResponse } from "../Models/expert";
import { AxiosError } from "axios";
import { handleAxiosError } from "../Services/Https/errorHandler";
import { enqueueSnackbar } from "notistack";

export const useExpert = () => {
  return useQuery<ExpertResponse, AxiosError>(["experts"], () => getExperts(), {
    onError: (error) => {
      const errorMessage = handleAxiosError(error);
      enqueueSnackbar(`Error fetching the experts: ${errorMessage}`, {
        variant: "error",
      });
    },
  });
};
