/**
 * Clears local storage, session storage, cookies, and IndexedDB entries based on specified item names.
 * @param {string[]} itemNames - An array of item names to clear from storage.
 */

export async function clearStorage(itemNames: string[]): Promise<void> {
  const shouldRemove = (key: string) => itemNames.some((name) => key === name);

  Object.keys(localStorage).forEach((key) => {
    if (shouldRemove(key)) {
      localStorage.removeItem(key);
    }
  });

  Object.keys(sessionStorage).forEach((key) => {
    if (shouldRemove(key)) {
      sessionStorage.removeItem(key);
    }
  });

  document.cookie.split(";").forEach((cookie) => {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie.trim();
    if (shouldRemove(name)) {
      document.cookie =
        name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
  });

  const databases = await indexedDB.databases();
  databases.forEach((db) => {
    if (db.name && itemNames.includes(db.name)) {
      indexedDB.deleteDatabase(db.name);
    }
  });
}
