import { Route, Routes } from "react-router-dom";
import Evaluation from "./Pages/Evaluation/Evaluation";
import LoginPage from "./Pages/Login";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import UnauthorizedPage from "./Pages/UnauthorizedPage/UnauthorizedPage";
import withAuth from "./Components/WithAuth/WithAuth";
import Dashboard from "./Pages/Dashboard/Dashboard";
import SingleCompanyComponent from "./Components/SingleCompanyComponent/SingleCompanyComponent";
import CompaniesPage from "./Pages/Companies";
import CreateCompanyPage from "./Pages/CreateCompany/CreateCompanyPage";
import UsersPage from "./Pages/Users/UsersPage";
import CreateUserPage from "./Pages/Users/CreateUserPage";
import UserProfilePage from "./Pages/Users/UserProfilePage";
import QuestionListPage from "./Pages/CreateQuestionsList/CreateQuestionsListPage";
import MyQuestionsPage from "./Pages/MyQuestions/MyQuestionsPage";
import SingleQuestionListPage from "./Pages/SingleQuestionList/SingleQuestionListPage";
import HomePage from "./Pages/HomePage/HomePage";
import DigitalPerformancePage from "./Pages/DigitalPerformancePage/DigitalPerformancePage";
import ExpertFormPage from "./Pages/ExpertFormPage/ExpertFormPage";
import KnowledgeBasePage from "./Pages/KnowledgeBasePage/KnowledgeBasePage";
import KnowledgeBaseAllPage from "./Pages/KnowledgeBaseAllPage/KnowledgeBaseAllPage";
import UploadCSVPage from "./Pages/CSVUploadPage/CSVUploadPage";
import CertCategoryPage from "./Pages/CertCategoryPage/CertCategoryPage";
import CertCategoryPageAll from "./Pages/CertCategoryPageAll/CertCategoryPageAll";
import { RouteGuard } from "./Components/Unauthorized/Unauthorized";
import { UserRole } from "./Constants/Enums/companyStatus";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={withAuth(Dashboard, "/login", false)}>
        <Route path="" exact element={<HomePage />} />
        <Route
          path="evaluation"
          element={
            <RouteGuard
              allowedRoles={[UserRole.ADMIN, UserRole.EVALUATOR, UserRole.USER]}
            >
              <Evaluation />
            </RouteGuard>
          }
        />
        <Route path="company/:companyId" element={<SingleCompanyComponent />} />
        <Route path="company" element={<CompaniesPage />} />
        <Route path="/company/create" element={<CreateCompanyPage />} />
        <Route path="/question_list" element={<QuestionListPage />} />
        <Route path="/questions_list" element={<MyQuestionsPage />} />
        <Route
          path="/question_list/:listId"
          element={<SingleQuestionListPage />}
        />
        <Route path="/cert_category" element={<CertCategoryPageAll />} />
        <Route path="/cert_category/create" element={<CertCategoryPage />} />
        <Route
          path="/cert_category/edit/:certCategoryId"
          element={<CertCategoryPage />}
        />
        <Route
          path="/expert-form"
          element={
            <RouteGuard
              allowedRoles={[
                UserRole.ADMIN,
                UserRole.EVALUATOR,
                UserRole.USER,
                UserRole.FORM,
              ]}
            >
              <ExpertFormPage />
            </RouteGuard>
          }
        />
        <Route
          path="/digital-performance"
          element={
            <RouteGuard
              allowedRoles={[UserRole.ADMIN, UserRole.EVALUATOR, UserRole.USER]}
            >
              <DigitalPerformancePage />
            </RouteGuard>
          }
        />
        <Route path="/knowledge_base/" element={<KnowledgeBaseAllPage />} />
        <Route path="/knowledge_base/create" element={<KnowledgeBasePage />} />
        <Route
          path="/knowledge_base/edit/:knowledgeBaseId"
          element={<KnowledgeBasePage />}
        />
        <Route path="/upload-csv" element={<UploadCSVPage />} />
      </Route>
      <Route path="/" element={withAuth(Dashboard, "/login", true)}>
        <Route path="users" element={<UsersPage />} />
        <Route path="/users/create" element={<CreateUserPage />} />
        <Route path="/user/:userId" element={<UserProfilePage />} />
      </Route>

      <Route path="/login" element={<LoginPage />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRoutes;
