import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  createQuestionListFull,
  exportQuestionList,
  fetchQuestionListDetails,
  fetchQuestionLists,
  fetchUserQuestionLists,
  toggleArchiveStatus,
} from "../Services/Https/questions";
import {
  exportQuestionListResponse,
  QuestionListDetailsResponse,
  QuestionListFull,
  QuestionListResponse,
} from "../Models/question";

export const useQuestionListsforUser = (userId: number) => {
  return useQuery<QuestionListResponse, Error>(["questionLists", userId], () =>
    fetchUserQuestionLists(userId)
  );
};

export const useQuestionLists = () => {
  return useQuery<QuestionListResponse, Error>(["questionLists"], () =>
    fetchQuestionLists()
  );
};

export const useToggleArchiveStatus = () => {
  const queryClient = useQueryClient();

  return useMutation((id: number) => toggleArchiveStatus(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("questionLists");
    },
  });
};

const useQuestionListDetails = (questionListId: unknown) => {
  return useQuery<QuestionListDetailsResponse, Error>(
    ["questionListDetails", questionListId],
    () => fetchQuestionListDetails(Number(questionListId)),
    { enabled: !!questionListId }
  );
};

export default useQuestionListDetails;

export const useCreateQuestionList = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (questionListFull: QuestionListFull) =>
      createQuestionListFull(questionListFull),
    {
      onSuccess: () => {
        // Invalidate and refetch the question lists query after mutation
        queryClient.invalidateQueries("questionLists");
      },
    }
  );
};

export const useQuestionListExtraction = (questionListId: number | null) => {
  return useQuery<exportQuestionListResponse | null, Error>(
    ["exportQuestionList", questionListId],
    () => (questionListId !== null ? exportQuestionList(questionListId) : null),
    {
      enabled: !!questionListId,
    }
  );
};
