import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Divider,
  Grid,
  Chip,
  Box,
} from "@mui/material";
import PolicyIcon from "@mui/icons-material/Policy";
import color from "../../Constants/colors";
import { formatDate } from "../../helpers/helpers";
import { SingleCertificationResponse } from "../../Models/certification";

const CertCategoryModal = ({
  open,
  onClose,
  certCategory,
  isLoading,
}: {
  open: boolean;
  onClose: () => void;
  certCategory: SingleCertificationResponse | null;
  isLoading: boolean;
}) => {
  if (isLoading) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  if (!certCategory) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent
        sx={{
          overflowY: "auto",
          padding: "2rem",
          color: color.white,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{
                color: color.white,
                fontWeight: "bold",
              }}
            >
              {certCategory.name}
            </Typography>
            {certCategory.digital_performance && (
              <Typography
                sx={{
                  color: color.white,
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                  opacity: 0.6,
                  cursor: "pointer",
                  "&:hover": {
                    opacity: 1,
                    textDecoration: "underline",
                    textDecorationColor: "orange",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Digital Performance{" "}
                <PolicyIcon
                  fontSize="small"
                  sx={{
                    color: color.btn_dark,
                    marginLeft: 1,
                  }}
                />
              </Typography>
            )}
          </Grid>
          {certCategory.knowledge_bases.length > 0 && (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  color: color.white,
                  fontWeight: "bold",
                }}
              >
                Knowledge Bases
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "1rem",
                  flexWrap: "wrap",
                }}
              >
                {certCategory.knowledge_bases?.map(
                  (name: any, index: number) => (
                    <Chip
                      key={index}
                      sx={{
                        color: color.white,
                        backgroundColor: color.btn_dark,
                        fontWeight: "bold",
                      }}
                      label={name.name}
                      variant="outlined"
                    />
                  )
                )}
              </Box>
            </Grid>
          )}
          {certCategory.expert && (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  color: color.white,
                  fontWeight: "bold",
                }}
              >
                Expert
              </Typography>
              <Divider sx={{ backgroundColor: color.white }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      margin: "10px 0 5px 0",
                      fontSize: "1.1rem",
                      fontWeight: "normal",
                    }}
                  >
                    {certCategory.expert.title}
                  </Typography>
                  <Typography
                    sx={{
                      margin: "5px 0",
                      fontSize: "0.9rem",
                      fontWeight: "normal",
                    }}
                  >
                    {certCategory.expert.description}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: color.white,
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      opacity: 0.6,
                      cursor: "pointer",
                      "&:hover": {
                        opacity: 1,
                        textDecoration: "underline",
                        textDecorationColor: "orange",
                      },
                      marginTop: "10px",
                    }}
                  >
                    Owner: {certCategory.expert.owner.username}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: color.white,
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      opacity: 0.6,
                      cursor: "pointer",
                      "&:hover": {
                        opacity: 1,
                        textDecoration: "underline",
                        textDecorationColor: "orange",
                      },
                      marginTop: "10px",
                    }}
                  >
                    Created at: {formatDate(certCategory.expert.created)}
                  </Typography>
                  <Typography
                    sx={{
                      color: color.white,
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      opacity: 0.6,
                      cursor: "pointer",
                      "&:hover": {
                        opacity: 1,
                        textDecoration: "underline",
                        textDecorationColor: "orange",
                      },
                    }}
                  >
                    Updated at: {formatDate(certCategory.expert.updated)}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            marginBottom: "10px",
            marginTop: "10px",
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CertCategoryModal;
