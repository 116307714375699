import React from "react";
import { Typography } from "@mui/material";
import FormTextField from "./FormTextField";
import colors from "../../Constants/colors";
import { ExpertFormState } from "../../Models/expert";

interface RolesAndResponsibilitiesProps {
  formState: ExpertFormState;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  validationErrors: any;
}

const RolesAndResponsibilities: React.FC<RolesAndResponsibilitiesProps> = ({
  formState,
  handleChange,
  validationErrors,
}) => {
  return (
    <>
      <Typography
        variant="h5"
        sx={{
          flexGrow: 1,
          color: colors.white,
          textAlign: "center",
          marginTop: "20px",
          marginLeft: {
            xs: "0",
            md: "30px",
          },
        }}
      >
        Roles and Responsibilities
      </Typography>
      <FormTextField
        label="Primary Responsibilities"
        name="primaryResponsibilities"
        value={formState.primaryResponsibilities}
        onChange={handleChange}
        info="Outline the main responsibilities of the expert in their role. Example: Overseeing IT projects, managing development teams."
        minRows={1}
        error={!!validationErrors.primaryResponsibilities}
        helperText={validationErrors.primaryResponsibilities}
      />
      <FormTextField
        label="Problem-Solving Abilities"
        name="problemSolvingAbilities"
        value={formState.problemSolvingAbilities}
        onChange={handleChange}
        info="Detail the problem-solving capabilities relevant to the expert's environment. Example: Excellent at troubleshooting network issues, innovative solution developer."
        minRows={1}
        error={!!validationErrors.problemSolvingAbilities}
        helperText={validationErrors.problemSolvingAbilities}
      />
      <FormTextField
        label="Knowledge Depth"
        name="knowledgeDepth"
        value={formState.knowledgeDepth}
        onChange={handleChange}
        info="Describe the level of in-depth knowledge the expert has in certain areas. Example: In-depth knowledge of cloud computing and cybersecurity."
        minRows={1}
        error={!!validationErrors.knowledgeDepth}
        helperText={validationErrors.knowledgeDepth}
      />
    </>
  );
};

export default RolesAndResponsibilities;
