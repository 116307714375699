import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Popper,
  Paper,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Assessment, Home } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { FC, useState, useRef, useEffect } from "react";
import { UserRole } from "../../Constants/Enums/companyStatus";
import PeopleIcon from "@mui/icons-material/People";
import BusinessIcon from "@mui/icons-material/Business";
import color from "../../Constants/colors";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import AddIcon from "@mui/icons-material/Add";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import ApprovalIcon from "@mui/icons-material/Approval";
import PerformanceIcon from "@mui/icons-material/Speed";
import StorageIcon from "@mui/icons-material/Storage";
import SchoolIcon from "@mui/icons-material/School";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ReleaseNotes from "../ReleaseNotes/ReleaseNotes";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";

interface Props {
  path?: string;
  text: string;
  icon: any;
  disabled?: boolean;
  externalUrl?: string;
}

export const DashboardMenuItem: FC<Props> = ({
  path,
  text,
  icon,
  disabled,
  externalUrl,
}) => {
  const LinkComponent = externalUrl ? "a" : Link;
  const linkProps = externalUrl
    ? { href: externalUrl, target: "_blank", rel: "noopener noreferrer" } // open external links in new tab
    : { to: path };

  return (
    <ListItem
      disablePadding
      component={disabled ? "div" : LinkComponent}
      {...(disabled ? undefined : linkProps)}
    >
      <ListItemButton disabled={disabled}>
        <ListItemIcon sx={{ color: color.primary_dark }}>{icon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="caption" sx={{ color: color.primary_dark }}>
              {text}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

interface CollapseProps {
  text: string;
  icon: any;
  children: any;
}

export const DashboardMenuCollapse: FC<CollapseProps> = ({
  children,
  icon,
  text,
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((value) => !value);
  return (
    <ListItem disablePadding>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <ListItemButton onClick={toggleOpen}>
          <ListItemIcon sx={{ color: color.primary_dark }}>{icon}</ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="caption" sx={{ color: color.primary_dark }}>
                {text}
              </Typography>
            }
          />
        </ListItemButton>
        <Collapse in={open} style={{ paddingLeft: "1em" }}>
          {children}
        </Collapse>
      </div>
    </ListItem>
  );
};

type RoleType = {
  role:
    | UserRole.USER
    | UserRole.EVALUATOR
    | UserRole.ADMIN
    | UserRole.FORM
    | UserRole.DUSSMANN;
};

export const DashboardMenu: FC<RoleType> = ({ role }) => {
  const [releaseNotesOpen, setReleaseNotesOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  const releaseButtonRef = useRef<HTMLDivElement>(null);

  const toggleReleaseNotes = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (releaseNotesOpen) {
      setReleaseNotesOpen(false);
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
      setReleaseNotesOpen(true);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popperRef.current &&
      !popperRef.current.contains(event.target as Node) &&
      releaseButtonRef.current &&
      !releaseButtonRef.current.contains(event.target as Node)
    ) {
      setReleaseNotesOpen(false);
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const questionList = useLocation().state as any;

  return (
    <List>
      <DashboardMenuItem path="/" text="Home" icon={<Home />} />
      {role !== UserRole.FORM && role !== UserRole.DUSSMANN && (
        <>
          <DashboardMenuItem
            path="/company/create"
            text="Create a Company"
            icon={<BusinessIcon />}
          />
        </>
      )}

      <DashboardMenuItem
        path="company"
        text="My Profile"
        icon={<AccountCircleIcon />}
      />

      {role !== UserRole.DUSSMANN && (
        <DashboardMenuCollapse text="Integrations" icon={<CloudSyncIcon />}>
          <DashboardMenuItem
            path="/expert-form"
            text="Order an Expert"
            icon={
              <img
                src="/alan-icon.png"
                alt="Alan Icon"
                style={{ width: 24, height: 24 }}
              />
            }
          />
          {role === UserRole.ADMIN && (
            <DashboardMenuItem
              path="/upload-csv"
              text="Dussmann Import"
              icon={
                <img
                  src="/dussmann-logo.png"
                  alt="Dussmann Logo"
                  style={{ width: 24, height: 24 }}
                />
              }
            />
          )}
        </DashboardMenuCollapse>
      )}

      {role === UserRole.DUSSMANN && (
        <DashboardMenuCollapse text="Integrations" icon={<CloudSyncIcon />}>
          <DashboardMenuItem
            path="/upload-csv"
            text="Dussmann Import"
            icon={
              <img
                src="/dussmann-logo.png"
                alt="Dussmann Logo"
                style={{ width: 24, height: 24 }}
              />
            }
          />
        </DashboardMenuCollapse>
      )}

      {(role === UserRole.ADMIN || role === UserRole.EVALUATOR) && (
        <DashboardMenuCollapse text="Company Audit" icon={<DynamicFormIcon />}>
          <DashboardMenuItem
            path="evaluation"
            text="Certificate Evaluation"
            icon={<Assessment />}
          />
          <DashboardMenuItem
            path="/digital-performance"
            text="Digital Performance "
            icon={<PerformanceIcon />}
          />
          <DashboardMenuItem
            path="/question_list/"
            text="Create a Question List"
            icon={<AddIcon />}
            disabled={!!questionList}
          />
          <DashboardMenuItem
            path="/questions_list/"
            text="My Question Lists"
            icon={<HelpCenterIcon />}
          />
        </DashboardMenuCollapse>
      )}

      {role === UserRole.USER && (
        <DashboardMenuCollapse text="Audit" icon={<DynamicFormIcon />}>
          <DashboardMenuItem
            path="evaluation"
            text="Evaluation"
            icon={<Assessment />}
          />
          <DashboardMenuItem
            path="/digital-performance"
            text="Digital Performance "
            icon={<PerformanceIcon />}
          />
        </DashboardMenuCollapse>
      )}

      <ListItem disablePadding>
        <ListItemButton onClick={toggleReleaseNotes} ref={releaseButtonRef}>
          <ListItemIcon sx={{ color: color.primary_dark }}>
            <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="caption" sx={{ color: color.primary_dark }}>
                Release Notes
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem>

      <Popper
        open={releaseNotesOpen}
        anchorEl={anchorEl}
        placement="right"
        style={{ zIndex: 9999 }}
        ref={popperRef}
        modifiers={[
          {
            name: "preventOverflow",
            options: {
              boundary: "window",
            },
          },
        ]}
      >
        <Paper
          style={{
            backgroundColor: "white",
            opacity: 1,
            boxShadow: "0px 2px 10px rgba(0,0,0,0.2)",
            width: "300px",
          }}
        >
          <ReleaseNotes />
        </Paper>
      </Popper>

      <hr />
      {role === UserRole.ADMIN && (
        <>
          <DashboardMenuItem path="users" text="Users" icon={<PeopleIcon />} />
          <DashboardMenuCollapse
            text="Certification Categories"
            icon={<ApprovalIcon />}
          >
            <DashboardMenuItem
              path="/cert_category/create"
              text="Create new"
              icon={<AddIcon />}
            />
            <DashboardMenuItem
              path="cert_category/"
              text="View all"
              icon={<StorageIcon />}
            />
          </DashboardMenuCollapse>
          <DashboardMenuCollapse text="Knowledge Bases" icon={<SchoolIcon />}>
            <DashboardMenuItem
              path="/knowledge_base/create"
              text="Create new"
              icon={<AddIcon />}
            />
            <DashboardMenuItem
              path="knowledge_base/"
              text="View all"
              icon={<StorageIcon />}
            />
          </DashboardMenuCollapse>
          <DashboardMenuItem
            externalUrl="https://corporate-momentum.sentry.io/issues/?environment=development&referrer=sidebar&statsPeriod=1h"
            text="Sentry Monitoring"
            icon={<MonitorHeartIcon />}
          />
        </>
      )}
    </List>
  );
};
