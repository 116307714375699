import React from "react";
import {
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Box,
  Grid,
} from "@mui/material";
import color from "../../Constants/colors";
import { CategoryDetail } from "../../Models/evaluation";
import { getTitleFromCamelcase } from "../../helpers/helpers";
import SourceBar from "../KnowledgeSource/SourceBar";

interface EvaluationCategoryProps {
  categoryDetail: CategoryDetail;
  categoryKey: string;
}

const EvaluationCategory: React.FC<EvaluationCategoryProps> = ({
  categoryDetail,
  categoryKey,
}) => {
  const gradePercentage = categoryDetail.category_grade;
  const title = getTitleFromCamelcase(categoryKey);
  return (
    <Grid item mt={4}>
      <Card raised sx={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
        <CardContent>
          <Typography
            gutterBottom
            variant="body1"
            sx={{ color: color.primary_dark }}
          >
            {title}
          </Typography>
          <Typography variant="body2" sx={{ color: color.secondary }}>
            {categoryDetail.summary}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={gradePercentage}
                sx={{
                  height: 8,
                  borderRadius: 4,
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: color.btn_dark,
                  },
                }}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography
                variant="body2"
                style={{ color: color.primary_dark }}
              >{`${categoryDetail.category_grade.toFixed(1)}/100`}</Typography>
            </Box>
          </Box>
          <Typography
            variant="body2"
            style={{ marginTop: 8, color: color.accent_dark }}
          >
            Note: {categoryDetail.note}
          </Typography>
          <SourceBar
            sources={categoryDetail.company_data_sources}
            title="Company data taken into account:"
          />
          <SourceBar
            sources={categoryDetail.question_category_sources}
            title="Knowledge the evaluation is based on:"
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EvaluationCategory;
