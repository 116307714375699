import React from "react";
import MyQuestions from "../../Components/MyQuestions/MyQuestions";
import { useQuery } from "react-query";
import { getUser } from "../../Services/Https/auth";
import { UserRole } from "../../Constants/Enums/companyStatus";

const MyQuestionsPage: React.FC = () => {
  const { data: userData } = useQuery("user", getUser);

  const userRole: UserRole = userData?.role ?? UserRole.USER;

  return (
    <div>
      <MyQuestions userRole={userRole} />
    </div>
  );
};

export default MyQuestionsPage;
