import CertCategoryAll from "../../Components/CertificationCategories/CertCategoryAll";

const CertCategoryPageAll = () => {
  return (
    <div>
      <CertCategoryAll />
    </div>
  );
};

export default CertCategoryPageAll;
