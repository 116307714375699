import React from "react";
import { useQuery } from "react-query";
import { Box, Typography, Grid } from "@mui/material";
import { styled } from "@mui/system";
import FieldCard from "./FieldCard";
import Footer from "./Footer";
import HeroSection from "./HeroSection";
import color from "../../Constants/colors";
import { Assessment, Speed } from "@mui/icons-material";
import { UserRole } from "../../Constants/Enums/companyStatus";
import { getUser } from "../../Services/Https/auth";

const canAccessCard = (
  requiredRoles: UserRole[],
  userRole: UserRole
): boolean => !requiredRoles.includes(userRole);

const Root = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  fontFamily: "'Work Sans', sans-serif",
});

const ScrollSection = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(10),
  backgroundColor: color.primary,
  color: color.primary_dark,
  width: "100vw",
  flex: 1,
}));

const ContentSection = styled(Box)(({ theme }) => ({
  margin: theme.spacing(5),
}));

const DecorativeLine = styled(Box)(({ theme }) => ({
  width: "100px",
  height: "4px",
  background: color.accent,
  margin: "0 auto",
  marginBottom: theme.spacing(2),
}));

const SmallGrayText = styled(Typography)(({ theme }) => ({
  color: color.primary_medium,
  fontSize: "16px",
  textAlign: "center",
  marginBottom: theme.spacing(4),
  padding: "0 2rem",
}));

const HomePage: React.FC = () => {
  const { data: userData } = useQuery("user", getUser);
  const userRole: UserRole = userData?.role ?? UserRole.USER;

  return (
    <Root>
      <HeroSection />
      <ScrollSection>
        <DecorativeLine />
        <Typography
          variant="h4"
          component="h2"
          gutterBottom
          style={{
            fontFamily: "'Barlow', sans-serif",
            textAlign: "center",
            marginBottom: "1rem",
          }}
        >
          Explore Our Services
        </Typography>
        <SmallGrayText>
          Discover how our services can help you transform and grow your
          business.
        </SmallGrayText>
        <ContentSection sx={{ marginTop: "3rem" }}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={4}>
              <FieldCard
                title="Certification Standard Evaluation"
                description="Enhance compliance management with our AI-driven evaluation tool. It assesses your adherence to industry standards, grades performance, and provides insights to help you achieve certifications efficiently and confidently."
                hoverText="Upload your data for a thorough compliance review against certification standards. Receive detailed reports and strategic advice to drive your organization toward ongoing improvement and industry leadership."
                icon={<Assessment style={{ width: 60, height: 60 }} />}
                link="/evaluation"
                disabled={canAccessCard(
                  [UserRole.ADMIN, UserRole.EVALUATOR, UserRole.USER],
                  userRole
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FieldCard
                title="Digital Audit Service"
                description="Gain a full understanding of your digital footprint with an AI-driven audit. Our service reviews your online presence, providing critical insights that allow you to refine your strategy and maximize digital impact."
                hoverText="This in-depth digital audit uncovers strengths and areas for improvement in your online presence, empowering your company with data-driven recommendations to enhance engagement and visibility."
                icon={<Speed style={{ width: 60, height: 60 }} />}
                link="/digital-performance"
                disabled={canAccessCard(
                  [UserRole.ADMIN, UserRole.EVALUATOR, UserRole.USER],
                  userRole
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FieldCard
                title="NLP Information Processing"
                description="Navigate and filter vast amounts of data with ease using NLP-driven analysis. Extract key information efficiently, ensuring that only the most relevant data is highlighted for actionable insights."
                hoverText="Our NLP processing tool enables you to streamline data analysis by filtering out unnecessary details, allowing your team to focus on high-impact information that supports effective decision-making."
                icon={
                  <img
                    src="/natural-language-processing.png"
                    alt="NLP logo"
                    style={{ width: 60, height: 60 }}
                  />
                }
                link="#"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FieldCard
                title="AI Expert Consultation"
                description="Get real-time insights and expert consultation powered by AI. Access evaluations that are tailored to your business needs, empowering you to make well-informed, strategic decisions."
                hoverText="Interact with AI experts on the Alan platform for in-depth discussions and evaluations that bring clarity to your business challenges, helping you make data-driven improvements and strategic advancements."
                icon={
                  <img
                    src="/alan-partner_badge.png"
                    alt="Alan Partner Badge"
                    style={{ width: 200, height: 60 }}
                  />
                }
                link="/expert-form"
                disabled={canAccessCard(
                  [
                    UserRole.ADMIN,
                    UserRole.EVALUATOR,
                    UserRole.USER,
                    UserRole.FORM,
                  ],
                  userRole
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FieldCard
                title="Hyper Automation"
                description="Revolutionize your operations with hyper automation, integrating digital processes to drive efficiency. Automate routine tasks and improve decision-making for a more streamlined workflow across your organization."
                hoverText="Our hyper automation services allow seamless integration of tools, platforms, and workflows, enabling you to scale operations and achieve consistent, high-quality outcomes with minimal manual intervention."
                icon={
                  <img
                    src="/quickwork.png"
                    alt="Quickwork logo"
                    style={{ width: 60, height: 60 }}
                  />
                }
                link="https://www.quickwork.co/"
                disabled={canAccessCard(
                  [
                    UserRole.ADMIN,
                    UserRole.EVALUATOR,
                    UserRole.USER,
                    UserRole.FORM,
                  ],
                  userRole
                )}
              />
            </Grid>
          </Grid>
        </ContentSection>
      </ScrollSection>
      <Footer />
    </Root>
  );
};

export default HomePage;
