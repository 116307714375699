import {
  Grid,
  Paper,
  TextField,
  Avatar,
  Button,
  InputAdornment,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LockOutlined } from "@mui/icons-material";

import { login, checkAuth } from "../../Services/Https/auth";
import color from "../../Constants/colors";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { clearStorage } from "../../Services/Functions/clearStorage";

const Login = () => {
  const paperStyle = {
    padding: 20,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    margin: "0 auto",
  };

  const avatarStyle = {
    backgroundColor: color.btn_dark,
    marginInline: "auto",
    marginBottom: "8px",
  };
  const btnstyle = { margin: "8px 0" };
  const textFieldStyle = { margin: "8px 0" };
  const [showPassword, setShowPassword] = useState(false);
  const [, setAuth] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAuth = async () => {
      try {
        const response = await checkAuth();
        setAuth(response.status === 200);
      } catch (error) {
        setAuth(false);
      }
    };
    fetchAuth();
  }, []);

  // useEffect(() => {
  //   if (auth) {
  //     navigate("/admin");
  //   }
  // }, [auth, navigate]);

  const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLogin = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    setIsLoggingIn(true);
    try {
      await clearStorage(["cw_conversation", "cw_clearence"]);
      const response = await login(username, password);
      if (response) {
        navigate("/");
      }
    } catch (error) {
      enqueueSnackbar("Authorization Failed", { variant: "error" });
    }
    setIsLoggingIn(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh",
        backgroundColor: color.primary_dark,
      }}
    >
      <Paper
        elevation={0}
        style={paperStyle}
        sx={{
          maxWidth: { xs: "80%", sm: 400, md: 500 },
        }}
      >
        <Grid container direction="column" alignItems="center">
          <Avatar style={avatarStyle}>
            <LockOutlined
              style={{
                color: color.white,
              }}
            />
          </Avatar>
          <Typography variant="h4" sx={{ color: color.btn_dark }}>
            Login
          </Typography>
        </Grid>
        <form onSubmit={handleLogin}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            style={textFieldStyle}
            required
            value={username}
            onChange={handleChangeUsername}
            InputLabelProps={{
              sx: {
                color: `${color.black} !important`,
              },
            }}
          />
          <TextField
            label="Password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            fullWidth
            style={textFieldStyle}
            required
            value={password}
            onChange={handleChangePassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? (
                      <Visibility style={{ color: color.btn_dark }} />
                    ) : (
                      <VisibilityOff style={{ color: color.btn_dark }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              sx: {
                color: `${color.black} !important`,
              },
            }}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={btnstyle}
            fullWidth
            disabled={isLoggingIn}
            sx={{
              backgroundColor: color.btn_dark,
              color: color.white,
              "&:hover": {
                backgroundColor: color.primary_dark,
                color: color.btn_dark,
              },
            }}
          >
            Submit
          </Button>
        </form>
      </Paper>
      <Box
        sx={{
          position: "fixed",
          left: 20,
          bottom: 20,
          zIndex: 1100,
        }}
      >
        <img
          src="logo_big.png"
          alt="Big Logo"
          style={{ maxWidth: "250px", maxHeight: "250px" }}
        />
      </Box>
    </Grid>
  );
};

export default Login;
