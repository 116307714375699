import CertCategory from "../../Components/CertificationCategories/CertCategory";

const CertCategoryPage = () => {
  return (
    <div>
      <CertCategory />
    </div>
  );
};

export default CertCategoryPage;
