import React from "react";
import { Grid, CircularProgress } from "@mui/material";
import { useQuery } from "react-query";
import CreateQuestionList from "../../Components/CreateQuestionsList/CreateQuestionsList";
import { getUser } from "../../Services/Https/auth";
import { UserRole } from "../../Constants/Enums/companyStatus";

const QuestionListPage: React.FC = () => {
  const { data: userData, isLoading, isError } = useQuery("user", getUser);

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (isError || !userData?.id) {
    return <div>Error loading user data</div>;
  }

  return (
    <CreateQuestionList
      userId={userData.id}
      currentUserRole={userData.role as UserRole}
    />
  );
};

export default QuestionListPage;
