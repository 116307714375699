import { useQuery } from "react-query";
import { fetchLanguages } from "../Services/Https/language";
import { Language } from "../Models/languageModel";
import { enqueueSnackbar } from "notistack";
import { handleAxiosError } from "../Services/Https/errorHandler";

export const useLanguages = () => {
  return useQuery<Language[], Error>(["languages"], () => fetchLanguages(), {
    onError: (error) => {
      const errorMessage = handleAxiosError(error);
      enqueueSnackbar(`Error fetching languages: ${errorMessage}`, {
        variant: "error",
      });
    },
  });
};
