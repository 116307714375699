import axios from "axios";
import {
  QuestionListResponse,
  QuestionListDetailsResponse,
  QuestionItem,
  QuestionListFull,
} from "../../Models/question";

export const fetchQuestionLists = async (
  page: number = 1,
  page_size: number = 10,
  search: string = ""
): Promise<QuestionListResponse> => {
  const { data } = await axios.get<QuestionListResponse>(
    `${process.env.REACT_APP_BASE_URL}/v1/question_list/`,
    {
      withCredentials: true,
      params: { page, page_size, search },
    }
  );
  return data;
};

export const setDefaultQuestionList = async (
  questionListId: number,
  categoryId: number
): Promise<{
  category_id: number;
  message: string;
  question_list_id: number;
}> => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/question_list/default/${questionListId}/`,
    {
      category_id: categoryId,
    },
    {
      withCredentials: true,
    }
  );
  return response.data;
};

export const fetchUserQuestionLists = async (
  userId: number
): Promise<QuestionListResponse> => {
  const { data } = await axios.get<QuestionListResponse>(
    `${process.env.REACT_APP_BASE_URL}/v1/question_list/user/${userId}/`,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const fetchQuestionListDetails = async (
  id: number
): Promise<QuestionListDetailsResponse> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/v1/question_list/${id}/`,
    {
      withCredentials: true,
    }
  );
  return response.data;
};

export const fetchDefaultQuestionListDetails = async (
  id: number
): Promise<QuestionItem> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/v1/question_list/certification/${id}/`,
    {
      withCredentials: true,
    }
  );
  return response.data;
};

export const deleteQuestionList = async (id: number): Promise<void> => {
  await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/v1/question_list/${id}/`,
    {
      withCredentials: true,
    }
  );
};

export const toggleArchiveStatus = async (id: number): Promise<void> => {
  await axios.put(
    `${process.env.REACT_APP_BASE_URL}/v1/question_list/archive/${id}/`,
    {},
    {
      withCredentials: true,
    }
  );
};

export const createQuestionListFull = async (
  questionListFull: QuestionListFull
): Promise<QuestionListFull> => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/question_list/full/`,
    questionListFull,
    {
      withCredentials: true,
    }
  );
  return response.data;
};

export const updateQuestionListFull = async (
  questionListId: number,
  questionListFull: QuestionListFull
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BASE_URL}/v1/question_list/full/${questionListId}/`,
    questionListFull,
    {
      withCredentials: true,
    }
  );
  return response;
};

export const copyQuestionList = async (id: number) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/question_list/copy/${id}/`,
    {},
    { withCredentials: true }
  );
  return response.data;
};

export const exportQuestionList = async (id: number) => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/v1/question_list/${id}/export/`,
    {
      withCredentials: true,
    }
  );
  return response.data;
};

export const importQuestionList = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/v1/question_list/import/`,
    formData,
    {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response.data;
};
