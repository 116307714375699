import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Button,
  Divider,
} from "@mui/material";
import { useCertifications } from "../../Hooks/useCertifications";
import { Certification } from "../../Models/certification";
import color from "../../Constants/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { UserRole } from "../../Constants/Enums/companyStatus";
import { QuestionListFull } from "../../Models/question";
import { v4 as uuidv4 } from "uuid";
import ListNameForm from "./ListNameForm";
import {
  createQuestionListFull,
  fetchQuestionListDetails,
  updateQuestionListFull,
} from "../../Services/Https/questions";
import { enqueueSnackbar } from "notistack";
import { handleAxiosError } from "../../Services/Https/errorHandler";

interface CreateQuestionListProps {
  userId: number;
  currentUserRole: UserRole | undefined;
}

const initialData: QuestionListFull = {
  list_name: "",
  certification_category_id: 3,
  categories: [
    {
      category_name: "",
      questions: [],
    },
  ],
};

const sanitizeQuestionListFull = (
  questionListFull: QuestionListFull,
  isEdit?: boolean
) => {
  const sanitizedList = {
    ...questionListFull,
    categories: questionListFull.categories.map((category: any) => ({
      category_name: category.category_name,
      questions: category.questions.map((question: any) => question.question),
    })),
  };

  if (isEdit) {
    delete sanitizedList.certification_category_id;
  }

  return sanitizedList;
};
const CreateQuestionList: React.FC<CreateQuestionListProps> = ({
  userId,
  currentUserRole,
}) => {
  const questionList = useLocation().state as any;
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [questionListFull, setQuestionListFull] =
    useState<QuestionListFull>(initialData);

  const isCreationButtonEnabled =
    questionListFull.list_name &&
    questionListFull.categories.length > 0 &&
    questionListFull.categories.every(
      (category) => category.category_name && category.questions.length > 0
    );

  const navigate = useNavigate();

  const {
    data: certifications,
    isError,
    isLoading,
  } = useCertifications(currentUserRole);

  useEffect(() => {
    if (!isLoading && !isError && certifications) {
      if (certifications.length > 0) {
        if (
          !questionListFull.certification_category_id ||
          !certifications.some(
            (cert) => cert.id === questionListFull.certification_category_id
          )
        ) {
          setQuestionListFull((prev) => ({
            ...prev,
            certification_category_id: certifications[0].id,
          }));
        }
      } else {
        setQuestionListFull((prev) => ({
          ...prev,
          certification_category_id: 0,
        }));
      }
    }
  }, [
    questionListFull.certification_category_id,
    certifications,
    isLoading,
    isError,
  ]);

  useEffect(() => {
    const fetchQuestionList = async (id: number) => {
      try {
        const questionListDetails = await fetchQuestionListDetails(id);
        const updatedQuestionList = {
          list_name: questionListDetails.name,
          certification_category_id:
            questionListDetails.certification_category_id,
          categories: questionListDetails.categories.map((category: any) => ({
            id: uuidv4(),
            category_name: category.name,
            questions: category.questions.map((question: any) => ({
              id: uuidv4(),
              question: question.question,
            })),
          })),
        };
        setQuestionListFull(updatedQuestionList);
      } catch (error) {
        const errorMessage = handleAxiosError(error);
        enqueueSnackbar(`Error fetching list: ${errorMessage}`, {
          variant: "error",
        });
      }
    };
    if (questionList) {
      setIsEdit(true);
      fetchQuestionList(questionList.item.id);
    }
  }, [questionList]);

  const handleFinishList = async (questionListFull: QuestionListFull) => {
    const sanitizedQuestionListFull =
      sanitizeQuestionListFull(questionListFull);
    try {
      await createQuestionListFull(sanitizedQuestionListFull);
      enqueueSnackbar("Question list created successfully.", {
        variant: "success",
      });
      navigate(`/questions_list`);
    } catch (error) {
      enqueueSnackbar("An error occurred while creating question list.", {
        variant: "error",
      });
    }
  };

  const handleFinishEditingList = async (
    questionListFull: QuestionListFull,
    questionListId: number
  ) => {
    const sanitizedEditedQuestionListFull = sanitizeQuestionListFull(
      questionListFull,
      true
    );
    try {
      await updateQuestionListFull(
        questionListId,
        sanitizedEditedQuestionListFull
      );
      enqueueSnackbar("Question list updated successfully.", {
        variant: "success",
      });
      navigate(`/questions_list`);
    } catch (error) {
      enqueueSnackbar("An error occurred while updating question list.", {
        variant: "error",
      });
    }
  };

  const handleCancel = () => {
    setQuestionListFull(initialData);
    navigate("/questions_list");
  };

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (isError) return <CircularProgress />;

  return (
    <Grid
      container
      display={"flex"}
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      width="100%"
    >
      <Grid
        item
        xs={12}
        width="80%"
        style={{ display: "flex", alignItems: "center" }}
      >
        <FormControl fullWidth style={{ marginRight: 0 }}>
          <InputLabel
            id="certification-select-label"
            sx={{
              "&.Mui-focused": {
                color: color.btn_dark,
              },
            }}
          >
            <Typography variant="body1" sx={{ color: color.white }}>
              Select Certificate
            </Typography>
          </InputLabel>
          <Select
            value={questionListFull.certification_category_id}
            onChange={(e) =>
              setQuestionListFull((prev) => ({
                ...prev,
                certification_category_id: e.target.value as number,
              }))
            }
            label="Select Certificate"
            labelId="certification-select-label"
            disabled={isEdit}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: "#032e4d",
                  opacity: 0.95,
                },
              },
            }}
          >
            {certifications &&
              certifications.map((certification: Certification) => (
                <MenuItem key={certification.id} value={certification.id}>
                  <Typography variant="body1" style={{ color: color.white }}>
                    {certification.name}
                  </Typography>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} width="80%">
        <ListNameForm
          isEdit={isEdit}
          questionListFull={questionListFull}
          setQuestionListFull={setQuestionListFull}
        />
      </Grid>
      <Grid item width="50%" sx={{ mt: 6 }}>
        <Divider
          sx={{ margin: "auto", width: "100%", borderColor: color.accent }}
        />
      </Grid>
      <Grid sx={{ mt: 6 }}>
        <Button
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
            marginBottom: 10,
          }}
          onClick={() => {
            if (!isEdit) {
              handleFinishList(questionListFull);
            } else {
              handleFinishEditingList(questionListFull, questionList.item.id);
            }
          }}
          disabled={!isCreationButtonEnabled}
        >
          <Typography variant="body2" sx={{ padding: 2 }}>
            {isEdit ? "Update the Question List" : "Create the Question List"}
          </Typography>
        </Button>
        <Button
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
            marginBottom: 10,
            marginLeft: 2,
          }}
          onClick={handleCancel}
        >
          <Typography variant="body2" sx={{ padding: 2 }}>
            Cancel
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default CreateQuestionList;
