import React from "react";
import { Box, Typography } from "@mui/material";
import { getFileIcon } from "../../helpers/helpers";

interface SourceProps {
  fileName: string;
}

const SourceLabel: React.FC<SourceProps> = ({ fileName }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
        margin: "4px 8px",
      }}
    >
      {getFileIcon(fileName)}
      <Typography variant="caption" sx={{ marginLeft: 1, fontSize: "0.75rem" }}>
        {fileName}
      </Typography>
    </Box>
  );
};

export default SourceLabel;
