import { ProcessingStatus } from "../../Constants/Enums/companyStatus";

const errors = {
  error: "Error happened while processing File",
  timeError: "Error: time limit exceeded",
};

export const statusToStringFile = (status: number): string => {
  switch (status) {
    case ProcessingStatus.WAITING:
      return "Waiting for processing";
    case ProcessingStatus.IN_PROGRESS:
      return "File is being processed";
    case ProcessingStatus.PROCESSED:
      return "File is processed";
    case ProcessingStatus.ERROR:
      return errors.error;
    case ProcessingStatus.TIME_LIMIT_EXCEEDED:
      return errors.timeError;
    default:
      return "Unknown Status";
  }
};

export const isStatusToStringFileError = (status: number): boolean => {
  const statusMessage = statusToStringFile(status);
  return statusMessage === errors.error || statusMessage === errors.timeError;
};
