import { Outlet } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  Box,
  CssBaseline,
  Drawer,
  Toolbar,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Logout } from "@mui/icons-material";
import { DashboardMenu } from "../../Components/DashboardMenu/DashboardMenu";
import { useState } from "react";
import { logout } from "../../Services/Https/auth";
import { useSnackbar } from "notistack";
import { getUser } from "../../Services/Https/auth";
import { useQuery } from "react-query";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import color from "../../Constants/colors";
import React from "react";
import ChatHead from "../../Components/Quickwork/QuickWorkChat";
import { UserRole } from "../../Constants/Enums/companyStatus";
import { clearStorage } from "../../Services/Functions/clearStorage";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  paddingTop: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#e0e4fc",
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const Dashboard = () => {
  const { data: userData } = useQuery("user", getUser);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const userRole = userData?.role;

  const handleConfirmationDialogOpen = () => {
    setConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = (
    confirmed: boolean,
    reason: string
  ) => {
    if (confirmed) {
      handleLogout();
    } else {
      setConfirmationDialogOpen(false);
    }
  };

  const handleDialogClose = (event: any, reason: string) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
      setConfirmationDialogOpen(false);
      return;
    }
    handleConfirmationDialogClose(false, reason);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    setIsLoggingOut(true);
    try {
      await clearStorage(["cw_conversation", "cw_clearence"]);

      const response = await logout();
      if (response) {
        window.location.href = "/login";
      }
    } catch (error) {
      enqueueSnackbar("Token delete unsuccessfull", { variant: "error" });
    }
    setIsLoggingOut(false);
  };

  if (userRole === undefined) {
    return <DashboardMenu role={UserRole.USER} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background: color.primary,
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Tooltip title="open menu" disableHoverListener={open}>
            <span>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { visibility: "hidden" }) }}
              >
                <MenuIcon sx={{ color: color.primary_dark }} />
              </IconButton>
            </span>
          </Tooltip>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              marginLeft: "45px",
            }}
          >
            <img
              src={isLargeScreen ? "/logo_big_blue.png" : "/commo_logo.png"}
              alt="Commo Logo"
              style={{ maxHeight: "50px" }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ color: color.primary_dark }} variant="body1">
              {userData?.name}
            </Typography>
            <Dialog
              open={confirmationDialogOpen}
              onClose={handleDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle id="alert-dialog-title">
                <Typography sx={{ color: color.white }}>
                  Logout confirmation
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography variant="caption" sx={{ color: color.white }}>
                    Are you sure you want to logout?
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{
                    backgroundColor: color.btn_dark,
                    color: color.white,
                    marginBottom: "10px",
                    marginTop: "10px",
                    "&:hover": {
                      backgroundColor: color.primary_dark,
                      color: color.btn_dark,
                    },
                  }}
                  variant="outlined"
                  onClick={() => {
                    handleConfirmationDialogClose(false, "");
                  }}
                >
                  No
                </Button>
                <Button
                  sx={{
                    backgroundColor: color.btn_dark,
                    color: color.white,
                    marginBottom: "10px",
                    marginTop: "10px",
                    "&:hover": {
                      backgroundColor: color.primary_dark,
                      color: color.btn_dark,
                    },
                  }}
                  variant="outlined"
                  onClick={() => {
                    handleConfirmationDialogClose(true, "");
                  }}
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            <Tooltip title="Logout">
              <IconButton
                color="inherit"
                onClick={handleConfirmationDialogOpen}
              >
                <Logout sx={{ color: color.primary_dark }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Tooltip title="Close menu">
            <IconButton onClick={handleDrawerClose} disabled={isLoggingOut}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon sx={{ color: color.primary_dark }} />
              ) : (
                <ChevronRightIcon sx={{ color: color.primary_dark }} />
              )}
            </IconButton>
          </Tooltip>
        </DrawerHeader>
        <DashboardMenu role={userRole} />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
        {userData && <ChatHead />}
      </Main>
    </Box>
  );
};

export default Dashboard;
