import { ProcessingStatus } from "../../Constants/Enums/companyStatus";

export const statusToStringWebsites = (status: number): string => {
  switch (status) {
    case ProcessingStatus.WAITING:
      return "Waiting for processing";
    case ProcessingStatus.IN_PROGRESS:
      return "Website is being processed";
    case ProcessingStatus.PROCESSED:
      return "Website is processed";
    case ProcessingStatus.ERROR:
      return "Error happened while processing website";
    case ProcessingStatus.TIME_LIMIT_EXCEEDED:
      return "Error: time limit exceeded";
    default:
      return "Unknown Status";
  }
};
