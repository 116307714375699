export enum CompanyStatus {
  WAITING_FOR_EVALUATION = 0,
  EVALUATED = 1,
  NEEDS_MORE_INFO = 2,
  EVALUATION_IN_PROGRESS = 3,
  ERROR = 4,
  GATHERING_DIGITAL_PERFORMANCE = 5,
  FETCHING_COMPANY_DETAILS = 6,
  PROCESSING_COMPANY_INFORMATIONS = 7,
  GRADING_COMPANY = 8,
  TIME_LIMIT_EXCEEDED = 9,
}
export enum FilteredContentCase {
  ALL = 0,
  ABOUT = 1,
  DOCUMENT = 2,
  WEBSITE = 3,
}

export enum UserRole {
  USER = 0,
  ADMIN = 1,
  EVALUATOR = 2,
  FORM = 3,
  DUSSMANN = 4,
}

export enum ModelLevel {
  BASIC = 0,
  ENTRY = 1,
  FULL = 2,
}

export enum ProcessingStatus {
  WAITING = 0,
  IN_PROGRESS = 1,
  PROCESSED = 2,
  ERROR = 3,
  TIME_LIMIT_EXCEEDED = 4,
}

export enum DocumentType {
  TXT = 0,
  WORD = 1,
  PDF = 2,
  POWERPOINT = 3,
  IMAGE = 4,
}

export enum KnowledgeBaseStatus {
  WAITING = 0,
  IN_PROGRESS = 1,
  PROCESSED = 2,
  ERROR = 3,
  TIME_LIMIT_EXCEEDED = 4,
}
