import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  PaginatedReleaseNotes,
  UpdateReleaseNoteRequest,
} from "../Models/releaseNote";
import {
  getAllReleaseNotes,
  createReleaseNote,
  deleteReleaseNote,
  updateReleaseNote,
} from "../Services/Https/releaseNote";

export const useGetAllReleaseNotes = (page: number, page_size: number) => {
  return useQuery<PaginatedReleaseNotes>(
    ["releaseNotes", page, page_size],
    () => getAllReleaseNotes(page, page_size),
    {
      keepPreviousData: true,
    }
  );
};

export const useCreateReleaseNote = () => {
  const queryClient = useQueryClient();
  return useMutation(createReleaseNote, {
    onSuccess: () => {
      queryClient.invalidateQueries("releaseNotes");
    },
  });
};

export const useDeleteReleaseNote = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteReleaseNote, {
    onSuccess: () => {
      queryClient.invalidateQueries("releaseNotes");
    },
  });
};

export const useUpdateReleaseNote = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, data }: { id: number; data: UpdateReleaseNoteRequest }) =>
      updateReleaseNote(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("releaseNotes");
      },
    }
  );
};
