import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import color from "../../Constants/colors";
import { CompanyInfo } from "../../Models/company";
import { getUserCategories } from "../../Services/Https/certifications";
import { enqueueSnackbar } from "notistack";
import { FilteredContentCase } from "../../Constants/Enums/companyStatus";
import { formatDate } from "../../helpers/helpers";
import { fetchFilteredContent } from "../../Services/Https/filteredcontent";

const entityTypeMap = {
  [FilteredContentCase.ALL]: "all",
  [FilteredContentCase.ABOUT]: "about",
  [FilteredContentCase.DOCUMENT]: "document",
  [FilteredContentCase.WEBSITE]: "website",
};

interface FilteredContentProps {
  open: boolean;
  onClose: () => void;
  companyInfo: CompanyInfo;
}

const FilteredContent: React.FC<FilteredContentProps> = ({
  open,
  onClose,
  companyInfo,
}) => {
  const [categories, setCategories] = useState<{ id: number; name: string }[]>(
    []
  );
  const [tabValue, setTabValue] = useState(FilteredContentCase.ALL);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesData = await getUserCategories();
        setCategories(
          categoriesData.map((cat) => ({ id: cat.id, name: cat.name }))
        );
      } catch (error) {
        enqueueSnackbar("Failed to fetch categories.", {
          variant: "error",
        });
      }
    };

    fetchCategories();
  }, []);

  const getCategoryNameById = (id: number): string => {
    const category = categories.find((cat) => cat.id === id);
    return category ? category.name : "Unknown Category";
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const filteredRows = () => {
    switch (tabValue) {
      case FilteredContentCase.ALL:
        return [
          ...(companyInfo.filtered_data_about || []).map((content) => ({
            ...content,
            entityType: entityTypeMap[FilteredContentCase.ABOUT],
          })),
          ...(companyInfo.filtered_data_document || []).map((content) => ({
            ...content,
            entityType: entityTypeMap[FilteredContentCase.DOCUMENT],
          })),
          ...(companyInfo.filtered_data_website || []).map((content) => ({
            ...content,
            entityType: entityTypeMap[FilteredContentCase.WEBSITE],
          })),
        ];
      case FilteredContentCase.ABOUT:
        return (companyInfo.filtered_data_about || []).map((content) => ({
          ...content,
          entityType: entityTypeMap[FilteredContentCase.ABOUT],
        }));
      case FilteredContentCase.DOCUMENT:
        return (companyInfo.filtered_data_document || []).map((content) => ({
          ...content,
          entityType: entityTypeMap[FilteredContentCase.DOCUMENT],
        }));
      case FilteredContentCase.WEBSITE:
        return (companyInfo.filtered_data_website || []).map((content) => ({
          ...content,
          entityType: entityTypeMap[FilteredContentCase.WEBSITE],
        }));
      default:
        return [];
    }
  };

  const rows = filteredRows().map((content, index) => {
    let entityName = "";

    if (content.entityType === entityTypeMap[FilteredContentCase.ABOUT]) {
      entityName = " - About Section";
    } else if (
      content.entityType === entityTypeMap[FilteredContentCase.DOCUMENT] &&
      content.entity_name
    ) {
      entityName = ` - ${content.entity_name}`;
    } else if (
      content.entityType === entityTypeMap[FilteredContentCase.WEBSITE] &&
      content.entity_name
    ) {
      entityName = ` - ${content.entity_name}`;
    } else {
      entityName = " - No specific content";
    }

    return {
      id: index + 1,
      certificationCategoryId: content.certification_category_id,
      filteredContentId: content.entity_id,
      lastEdit: content.creation_timestamp,
      entityId: content.entity_id,
      entityType: content.entityType,
      questionListName: content.question_list_name + entityName,
      questionListId: content.question_list_id,
    };
  });

  const handleDownload = async (
    entityId: string,
    entityType: string,
    questionListId: string | number
  ) => {
    try {
      const blob: Blob = await fetchFilteredContent(
        entityType,
        entityId,
        questionListId
      );
      const element = document.createElement("a");
      const url = URL.createObjectURL(blob);
      element.href = url;
      element.download = `filtered_content_${entityType}_${entityId}.md`;
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      URL.revokeObjectURL(url);
    } catch (error) {
      enqueueSnackbar("Failed to download the filtered content", {
        variant: "error",
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        "&.MuiDialog-paper": {
          margin: 2,
          padding: 2,
        },
      }}
    >
      <DialogTitle
        sx={{
          color: color.white,
          fontSize: "1.5rem",
          fontWeight: "bold",
        }}
      >
        Filtered Content
      </DialogTitle>
      <DialogContent>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="inherit"
          sx={{
            marginBottom: 2,
            color: color.white,
            backgroundColor: "transparent",
            "&.MuiTabs-indicator": {
              backgroundColor: color.btn_dark,
            },
          }}
        >
          <Tab label="All" value={FilteredContentCase.ALL} />
          <Tab label="About" value={FilteredContentCase.ABOUT} />
          <Tab label="Document" value={FilteredContentCase.DOCUMENT} />
          <Tab label="Website" value={FilteredContentCase.WEBSITE} />
        </Tabs>
        <TableContainer sx={{ marginTop: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", color: color.white }}>
                  Evaluation Category
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: color.white }}>
                  Question List and Content Description
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: color.white }}>
                  Last Edit
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", color: color.white }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell sx={{ color: color.white }}>
                      {getCategoryNameById(row.certificationCategoryId)}
                    </TableCell>
                    <TableCell sx={{ color: color.white }}>
                      {row.questionListName}{" "}
                    </TableCell>
                    <TableCell sx={{ color: color.white }}>
                      {formatDate(row.lastEdit)}
                    </TableCell>
                    <TableCell sx={{ color: color.white }}>
                      <IconButton
                        onClick={() =>
                          handleDownload(
                            row.entityId.toString(),
                            row.entityType,
                            row.questionListId.toString()
                          )
                        }
                        sx={{ color: color.white }}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    sx={{ color: color.white, textAlign: "center" }}
                  >
                    No filtered content available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end" }}>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: color.btn_dark,
            color: color.white,
            marginBottom: "10px",
            marginTop: "10px",
            "&:hover": {
              backgroundColor: color.primary_dark,
              color: color.btn_dark,
            },
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilteredContent;
