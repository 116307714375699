import { useQuery, useMutation } from "react-query";
import {
  Certification,
  SingleCertificationResponse,
} from "../Models/certification";
import {
  getCertCategories,
  getUserCategories,
  createCertCategory,
  editCertCategory,
  deleteCertCategory,
  getSingleCertCategory,
} from "../Services/Https/certifications";
import { UserRole } from "../Constants/Enums/companyStatus";
import { enqueueSnackbar } from "notistack";
import { handleAxiosError } from "../Services/Https/errorHandler";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

export const useCertification = (currentUserRole: UserRole | undefined) => {
  return useQuery<Certification[], Error>(
    ["certifications"],
    () =>
      currentUserRole === UserRole.ADMIN
        ? getCertCategories()
        : getUserCategories(),
    {
      keepPreviousData: true,
    }
  );
};

export const useCertifications = (currentUserRole?: UserRole | undefined) => {
  return useQuery<Certification[], Error>(
    ["certifications"],
    () =>
      currentUserRole === UserRole.ADMIN
        ? getCertCategories()
        : getUserCategories(),
    {
      keepPreviousData: true,
    }
  );
};

export const useSingleCertification = (id: number | undefined) => {
  return useQuery<SingleCertificationResponse, AxiosError>(
    ["certification", id],
    () => getSingleCertCategory(id!),
    {
      keepPreviousData: false,
      enabled: !!id,
    }
  );
};

export const useCreateCertification = () => {
  const navigate = useNavigate();
  return useMutation(
    (newCategory: Omit<Certification, "id">) => createCertCategory(newCategory),
    {
      onSuccess: () => {
        enqueueSnackbar(`Certification Category created successfully.`, {
          variant: "success",
        });
        navigate("/cert_category/");
      },
      onError: (error) => {
        const errorMessage = handleAxiosError(error);
        enqueueSnackbar(
          `Error creating Certification Category: ${errorMessage}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
};

export const useUpdateCertification = () => {
  const navigate = useNavigate();
  return useMutation(
    ({
      id,
      updatedCategory,
    }: {
      id: number;
      updatedCategory: Omit<Certification, "id">;
    }) => editCertCategory(id, updatedCategory),
    {
      onSuccess: () => {
        enqueueSnackbar("Successfully updated the Certification Category", {
          variant: "success",
        });
        navigate("/cert_category/");
      },
      onError: (error) => {
        const errorMessage = handleAxiosError(error);
        enqueueSnackbar(
          `Error while updating the Certification Category: ${errorMessage}`,
          {
            variant: "error",
          }
        );
      },
    }
  );
};

export const useDeleteCertification = () => {
  // const queryClient = useQueryClient();
  return useMutation(
    (id: number) => deleteCertCategory(id)
    // {
    //   onSuccess: () => {
    //     queryClient.invalidateQueries("certifications");
    //     enqueueSnackbar("Successfully deleted the Certification Category", {
    //       variant: "success",
    //     });
    //   },
    //   onError: (error) => {
    //     const errorMessage = handleAxiosError(error);
    //     enqueueSnackbar(
    //       `Error deleting the Certification Category: ${errorMessage}`,
    //       {
    //         variant: "error",
    //       }
    //     );
    //   },
    // }
  );
};

export const useAllCertifications = () => {
  return useQuery<Certification[], Error>(
    ["certifications"],
    () => getCertCategories(),
    {
      keepPreviousData: true,
    }
  );
};
