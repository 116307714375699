import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import color from "../../Constants/colors";
import { useNavigate } from "react-router-dom";
import theme from "../../Constants/theme";
import { useSortableData } from "../../Hooks/useSortableData";
import { useEffect, useState } from "react";
import ConfirmationDialog from "../UI/dialogs/ConfirmationDialog";
import {
  useDeleteCertification,
  useSingleCertification,
} from "../../Hooks/useCertifications";
import { Certification } from "../../Models/certification";
import { getCertCategories } from "../../Services/Https/certifications";
import { enqueueSnackbar } from "notistack";
import { handleAxiosError } from "../../Services/Https/errorHandler";
import CertCategoryModal from "./CertCategoryModal";

const CertCategoryAll = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [certCategories, setCertCategories] = useState<Certification[]>([]);
  const [selectedCertCategoryId, setSelectedCertCategoryId] = useState<
    number | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutate: deleteCertCategory } = useDeleteCertification();

  // TODO: useCertifications needs user role check.
  // Create a context in top level for user data so we can pass that data across all project
  // this way we remove the fetchCertCategories and use the hook

  // const { data: certCategories, isLoading, isError } = useCertifications();
  useEffect(() => {
    const fetchCertCategories = async () => {
      setIsLoading(true);
      try {
        const res = await getCertCategories();
        setCertCategories(res);
      } catch (error: any) {
        const errorMessage = handleAxiosError(error);
        setError(error);
        enqueueSnackbar(
          `Error fetching certification categories: ${errorMessage}`,
          {
            variant: "error",
          }
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchCertCategories();
  }, []);

  const { data: selecteCertCategory, isLoading: isCertCategoryLoading } =
    useSingleCertification(selectedCertCategoryId ?? 0);

  const handleConfirmationDialogClose = (confirmed: boolean) => {
    setConfirmationDialogOpen(false);
    if (confirmed && selectedCertCategoryId) {
      deleteCertCategory(selectedCertCategoryId, {
        onSuccess: () => {
          // Refetch categories after a successful deletion
          setCertCategories((prevCategories) =>
            prevCategories.filter(
              (category) => category.id !== selectedCertCategoryId
            )
          );
          enqueueSnackbar("Certification category deleted successfully", {
            variant: "success",
          });
        },
        onError: (error: any) => {
          const errorMessage = handleAxiosError(error);
          enqueueSnackbar(
            `Error deleting certification category: ${errorMessage}`,
            {
              variant: "error",
            }
          );
        },
      });
    }
  };

  const handleEditCertCategory = (certCategoryId: number) => {
    navigate(`/cert_category/edit/${certCategoryId}`);
  };

  const handleCertName = (certCategoryId: number) => {
    setSelectedCertCategoryId(certCategoryId);
    setIsModalOpen(true);
  };

  const handleModalClose = () => setIsModalOpen(false);

  const { sortedItems, sortField, sortOrder, handleSort } =
    useSortableData<Certification>(certCategories ?? [], "name");

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <div>Error: Failed to fetch Certification Categories list</div>;
  }

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20px",
        width: "100%",
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        style={{ color: color.white, marginBottom: "20px" }}
      >
        Certification Categories
      </Typography>

      <TableContainer
        sx={{
          width: matches ? "90%" : "30%",
          overflowX: "auto",
          padding: matches ? "0 1rem" : "0",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ color: color.white, cursor: "pointer", width: "70%" }}
                onClick={() => handleSort("name")}
              >
                Name
                {sortField === "name" && (
                  <TableSortLabel active direction={sortOrder} />
                )}
              </TableCell>
              <TableCell sx={{ color: color.white, width: "30%" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedItems.map((item) => (
              <TableRow
                key={item.id}
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": { backgroundColor: color.primary_dark },
                }}
              >
                <TableCell
                  sx={{
                    color: color.white,
                    fontWeight: "normal",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCertName(item.id)}
                >
                  <span>{item.name}</span>
                </TableCell>
                <TableCell style={{ color: color.white }}>
                  <Tooltip title={"Edit"}>
                    <span>
                      <IconButton
                        sx={{
                          color: color.btn_dark,
                        }}
                        onClick={() => handleEditCertCategory(item.id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={"Delete"}>
                    <span>
                      <IconButton
                        onClick={() => {
                          setSelectedCertCategoryId(item.id);
                          setConfirmationDialogOpen(true);
                        }}
                        sx={{
                          color: color.red,
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmationDialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
        title="Delete Certification Category"
        description="Confirmation to delete a Certification Category"
        content="Are you sure you want to delete this Certification Category?"
        actions={[
          {
            label: "No",
            onClick: () => handleConfirmationDialogClose(false),
          },
          {
            label: "Yes",
            onClick: () => handleConfirmationDialogClose(true),
          },
        ]}
      />

      <CertCategoryModal
        open={isModalOpen}
        onClose={handleModalClose}
        certCategory={selecteCertCategory ?? null}
        isLoading={isCertCategoryLoading}
      />

      <Button
        onClick={() => navigate("/cert_category/create")}
        sx={{
          marginTop: "2rem",
          marginBottom: "2rem",
          backgroundColor: color.btn_dark,
          color: color.white,
          padding: "10px 20px",
          borderRadius: "5px",
          border: "none",
          cursor: "pointer",
          width: "fit-content",
          "&:hover": {
            backgroundColor: color.primary_dark,
            color: color.btn_dark,
          },
        }}
      >
        Create New Certification Category
      </Button>
    </Grid>
  );
};

export default CertCategoryAll;
