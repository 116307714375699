import axios from "axios";
import { Language } from "../../Models/languageModel";

export const fetchLanguages = async (): Promise<Language[]> => {
  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/v1/evaluation/language/`,
    { withCredentials: true }
  );
  return response.data;
};
