import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Typography,
  Modal,
} from "@mui/material";
import { styled } from "@mui/system";
import { ArrowForward } from "@mui/icons-material";
import color from "../../Constants/colors";

const FieldCardContainer = styled(Card)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  transition: "transform 0.3s",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  "&:hover": {
    transform: "scale(1.05)",
  },
  "&:hover::after": {
    transform: "translate(0, 0)",
  },
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    right: 0,
    width: "200%",
    height: "200%",
    backgroundColor: color.btn_dark,
    transform: "translate(100%, -100%)",
    transition: "transform 0.8s ease-out, border-radius 0.8s ease-out",
    borderRadius: "8px",
    zIndex: 0,
  },
  "& h2, & p": {
    position: "relative",
    color: color.primary_dark,
    transition: "color 0.3s",
    zIndex: 1,
  },
  "&:hover h2, &:hover p": {
    color: color.white,
  },
  borderRadius: "16px",
  [theme.breakpoints.down("sm")]: {
    minHeight: "250px",
  },
  [theme.breakpoints.up("md")]: {
    minHeight: "350px",
  },
}));

const CardContentFull = styled(CardContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px",
  textAlign: "center",
});

const IconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: color.primary_dark,
  fontSize: "60px",
  marginBottom: theme.spacing(2),
  zIndex: 2,
  transition: "none",
}));

const ArrowButton = styled(IconButton)({
  position: "absolute",
  top: 10,
  right: 10,
  borderRadius: "50%",
  transition: "transform 0.3s, color 0.3s",
  zIndex: 2,
  "&:hover": {
    color: color.primary_dark,
  },
});

const ModalContent = styled(Box)({
  backgroundColor: color.white,
  padding: 16,
  borderRadius: "16px",
  zIndex: 3,
  textAlign: "center",
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  transition: "transform 0.3s, background-color 0.3s",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
});

interface FieldCardProps {
  title: string;
  description: string;
  hoverText: string;
  icon: React.ReactNode;
  link: string;
  disabled?: boolean;
}

const FieldCard: React.FC<FieldCardProps> = ({
  title,
  description,
  hoverText,
  icon,
  link,
  disabled = false,
}) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [modalPosition, setModalPosition] = useState<{
    top: number;
    left: number;
    width: number;
    height: number;
  }>({ top: 0, left: 0, width: 0, height: 0 });
  const cardRef = useRef<HTMLDivElement>(null);

  const handleCardClick = () => {
    if (disabled) {
      if (cardRef.current) {
        const { top, left, width, height } =
          cardRef.current.getBoundingClientRect();
        setModalPosition({
          top: top + height / 2,
          left: left + width / 2,
          width,
          height,
        });
      }
      setOpenModal(true);
    } else {
      if (link.startsWith("http")) {
        window.open(link, "_blank", "noopener,noreferrer");
      } else {
        navigate(link);
      }
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <FieldCardContainer ref={cardRef} onClick={handleCardClick}>
        <IconContainer>{icon}</IconContainer>
        <CardContentFull>
          <Typography variant="h5" component="h2" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" component="p">
            {description}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "block",
              },
            }}
          >
            {hoverText}
          </Typography>
        </CardContentFull>
        <ArrowButton>
          <ArrowForward />
        </ArrowButton>
      </FieldCardContainer>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        BackdropProps={{ invisible: true }}
      >
        <ModalContent
          style={{
            top: modalPosition.top,
            left: modalPosition.left,
            width: modalPosition.width,
            height: modalPosition.height,
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography
            variant="body1"
            component="p"
            sx={{
              color: color.primary_dark,
            }}
          >
            You do not have access to this page.
          </Typography>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FieldCard;
