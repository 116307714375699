import axios from "axios";

export const fetchFilteredContent = async (
  entity_type: string,
  entity_id: string,
  question_list_id: string | number
): Promise<Blob> => {
  const response = await axios.get<Blob>(
    `${process.env.REACT_APP_BASE_URL}/v2/filtered_content/${entity_type}/${entity_id}/?question_list_id=${question_list_id}`,
    {
      responseType: "blob",
      withCredentials: true,
    }
  );
  return response.data;
};
