import {
  Certification,
  SingleCertificationResponse,
} from "../../Models/certification";
import axios from "axios";

export const fetchCertification = async (): Promise<Certification[]> => {
  const { data } = await axios.get<Certification[]>(
    `${process.env.REACT_APP_BASE_URL}/cert_category/`,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const getCertCategories = async (): Promise<Certification[]> => {
  const { data } = await axios.get<Certification[]>(
    `${process.env.REACT_APP_BASE_URL}/v1/cert_category/`,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const getSingleCertCategory = async (
  id: number
): Promise<SingleCertificationResponse> => {
  const { data } = await axios.get<SingleCertificationResponse>(
    `${process.env.REACT_APP_BASE_URL}/v1/cert_category/${id}/`,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const createCertCategory = async (
  newCategory: Omit<Certification, "id">
): Promise<Certification> => {
  const { data } = await axios.post<Certification>(
    `${process.env.REACT_APP_BASE_URL}/v1/cert_category/`,
    newCategory,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const editCertCategory = async (
  id: number,
  updatedCategory: Omit<Certification, "id">
): Promise<Certification> => {
  const { data } = await axios.put<Certification>(
    `${process.env.REACT_APP_BASE_URL}/v1/cert_category/${id}/`,
    updatedCategory,
    {
      withCredentials: true,
    }
  );
  return data;
};

export const deleteCertCategory = async (id: number): Promise<void> => {
  await axios.delete(
    `${process.env.REACT_APP_BASE_URL}/v1/cert_category/${id}/`,
    {
      withCredentials: true,
    }
  );
};

export const getUserCategories = async (): Promise<Certification[]> => {
  const { data } = await axios.get<Certification[]>(
    `${process.env.REACT_APP_BASE_URL}/v1/user/certifications/`,
    {
      withCredentials: true,
    }
  );
  return data;
};
